import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import FloatingButton from './FloatingButton';
import Footer from './Footer';
import TawkToChat from './TawkToChat';
import TexasMap from './TexasMap';

function App() {
  const [videoSource, setVideoSource] = useState('/face.mp4');
  const [showMap, setShowMap] = useState(false);
  const [buttonText, setButtonText] = useState("Let's Begin Tap Here"); // Initial button text

  const handleFloatingButtonClick = () => {
    setShowMap(true); // Show the map when button is clicked
    setVideoSource(window.innerWidth <= 768 ? '/mobile_texas.mp4' : '/texas.mp4');
    setButtonText("SELECT YOUR RECYCLE CENTER"); // Update button text when showing the map
  };

  return (
    <div className="App">
      <video autoPlay loop muted playsInline className="background-video">
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Header /> 
      {showMap && <TexasMap />}
      <FloatingButton onClick={handleFloatingButtonClick} text={buttonText} />
      <TawkToChat /> 
      <Footer />
    </div>
  );
}

export default App;
