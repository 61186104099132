// Import necessary React and react-leaflet components along with Leaflet itself
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet'; // Leaflet for map functionality
import 'leaflet/dist/leaflet.css'; // Leaflet's default styles
import './TexasMap.css'; // Custom CSS for styling the map container
import icon4 from './icon4.png'; // Custom icon for the marker

// ChangeView component: A functional component to change the map view dynamically
function ChangeView({ center, zoom }) {
  const map = useMap(); // useMap is a hook to access the map instance
  map.setView(center, zoom); // Changes the view to the new center and zoom level
  return null; // Renders nothing, as its purpose is purely functional
}

// Defining a custom icon using Leaflet's API
const customIcon = new L.Icon({
  iconUrl: icon4, // The URL to the custom icon image
  iconSize: [50, 50], // Size of the icon in pixels
  iconAnchor: [25, 25], // Point of the icon which will correspond to marker's location
});

// TexasMap component: Main component to render the map
const TexasMap = () => {
  const position = [31.9686, -99.9018]; // Latitude and Longitude for the center of Texas
  const zoom = 6; // Initial zoom level
//22222222 making a name change adding the city to the code like below the original code was const markerPosition = [#acordinates];
  // Coordinates for a marker, e.g., Austin, TX
  const markerPositionAustin = [30.2672, -97.7431];
  const markerPositionHouston = [29.7604, -95.3698];
  const markerPositionElPaso = [31.7619, -106.4850];
  const markerPositionNavascota = [30.3870, -96.0877];
  const markerPositionDenton = [33.2148, -97.1331];
  const markerPositionThorndale = [33.5779, -97.2045];

 

  // Rendering the map container and its child components
  return (
    <div className="texas-map-container">
      <header>__________________________TEXAS</header>
      <MapContainer center={position} zoom={zoom} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* Dynamic view changer */}
        <ChangeView center={position} zoom={zoom} />
        {/* Marker for Texas Locations */}

        
        
        <Marker 
  position={markerPositionAustin} 
  icon={customIcon}
  eventHandlers={{
    click: () => {
      window.location.href = 'https://austin.artificialgrassrecycle.com';
    },
  }}
>
  <Popup>Austin, TX</Popup>
</Marker>
<Marker 
  position={markerPositionHouston} 
  icon={customIcon}
  eventHandlers={{
    click: () => {
      window.location.href = 'https://houston.artificialgrassrecycle.com';
    },
  }}
>
  <Popup>Houston, TX</Popup>
</Marker>
<Marker 
  position={markerPositionElPaso} 
  icon={customIcon}
  eventHandlers={{
    click: () => {
      window.location.href = 'https://elpaso.artificialgrassrecycle.com';
    },
  }}
>
  <Popup>El Paso, TX</Popup>
</Marker>
<Marker 
  position={markerPositionNavascota} 
  icon={customIcon}
  eventHandlers={{
    click: () => {
      window.location.href = 'https://navasota.artificialgrassrecycle.com';
    },
  }}
>
  <Popup>Navasota, TX</Popup>
</Marker>
<Marker 
  position={markerPositionDenton} 
  icon={customIcon}
  eventHandlers={{
    click: () => {
      window.location.href = 'https://denton.artificialgrassrecycle.com';
    },
  }}
>
  <Popup>Denton, TX</Popup>
</Marker>
<Marker 
  position={markerPositionThorndale} 
  icon={customIcon}
  eventHandlers={{
    click: () => {
      window.location.href = 'https://thorndale.artificialgrassrecycle.com';
    },
  }}
>
  <Popup>Thorndale, TX</Popup>
</Marker>


        
        {/* Additional markers can be added here */}
      </MapContainer>
      <footer>
        Choose different State:
        <select name="states" id="state-selector">
          <option value="california">California</option>
          <option value="florida">Florida</option>
          <option value="new-york">New York</option>
        </select>
      </footer>
    </div>
  );
};

export default TexasMap;
