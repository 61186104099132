import React from 'react';
import './FloatingButton.css';

// Update the component to accept 'text' as a prop
const FloatingButton = ({ onClick, text }) => {
  return (
    <button className="floating-button" onClick={onClick}>
      {text} {""}
    </button>
  );
};

export default FloatingButton;
